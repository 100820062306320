import styled from '@emotion/styled'
import { css } from '@emotion/core'

// Loading animations for the LoadingStep component
/* eslint-disable import/prefer-default-export */
export const OneTrustPrivacyPolicyEs = styled.section(
  () => css`
    padding: 50px 10px;
    max-width: 1200px;
    margin: auto;
  `
)
