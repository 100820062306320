import React, { useState } from 'react'
import * as Styled from './OneTrustPrivacyPolicyEs.styles'

const OneTrustPrivacyPolicyEs = () => {
  const [isLoaded, setIsLoaded] = useState(false)

  if (typeof document !== 'undefined') {
    const appendScript = (script) => document.body.appendChild(script)

    const script = document.createElement('script')
    // unique per site
    script.src =
      'https://privacyportal-cdn.onetrust.com/privacy-notice-scripts/otnotice-1.0.min.js'
    script.type = 'text/javascript'
    script.charSet = 'UTF-8'
    script.id = 'otprivacy-notice-script'
    script.async = true
    script.innerHTML = `settings="eyJjYWxsYmFja1VybCI6Imh0dHBzOi8vcHJpdmFjeXBvcnRhbC5vbmV0cnVzdC5jb20vcmVxdWVzdC92MS9wcml2YWN5Tm90aWNlcy9zdGF0cy92aWV3cyJ9"`
    script.onload = () => setIsLoaded(true)

    const loadNotices = document.createElement('script')
    loadNotices.type = 'text/javascript'
    loadNotices.charSet = 'UTF-8'
    // unique per site
    loadNotices.innerHTML = `OneTrust.NoticeApi.Initialized.then(function() {
    OneTrust.NoticeApi.LoadNotices([
      'https://privacyportal-cdn.onetrust.com/11ccab12-653b-4e89-b206-f783168adc9b/privacy-notices/654a6ee9-052b-4203-a1d1-4910fd631b8e.json',
    ])
  })`

    appendScript(script)

    if (isLoaded) {
      appendScript(loadNotices)
    }
  }

  return (
    <Styled.OneTrustPrivacyPolicyEs>
      {!isLoaded && (
        <h2 className="loading">Cargando política de privacidad...</h2>
      )}
      {/* unique per site */}
      <div id="otnotice-654a6ee9-052b-4203-a1d1-4910fd631b8e" />
    </Styled.OneTrustPrivacyPolicyEs>
  )
}

export default OneTrustPrivacyPolicyEs
